// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonContainer-container-3GAOR {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n  align-items: baseline;\n  gap: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ButtonContainer-container-3GAOR"
};
module.exports = exports;
